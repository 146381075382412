import Navbar from '../../Components/TopNavBar/Navbar'
import Login from '../../Components/Auth/Login'

export default function LoginRoute() {
  return (
    <>
      <Navbar />
      <Login />
    </>
  )
}



