import Navbar from '../../Components/TopNavBar/Navbar'
import Register from '../../Components/Auth/Register'

export default function RegRoute() {
  return (
    <>
      <Navbar />
      <Register />
    </>
  )
}



