import Navbar from '.././Components/TopNavBar/Navbar'
import Home from '.././Components/Home/Home'

export default function HomeRoute() {
  return (
    <>
      <Navbar />
      <Home />
    </>

  )
}
